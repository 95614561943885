import * as React from "react";
import { Link } from "gatsby";
import { VictoryPie, VictoryLabel, VictoryContainer, VictoryTooltip, VictoryScatter, VictoryLine, VictoryChart, VictoryAxis } from "victory";

//components
import TileGrid from '../components/tile-grid.js';

//imgs
import ImgBallSpeed from "../visuals/images/ball-speed.png";
import ImgBallSpeed_mobile from "../visuals/images/ball-speed-mobile.webp";
import ImgBallUx from "../visuals/images/ball-ux.png";
import ImgBallUx_mobile from "../visuals/images/ball-ux-mobile.webp";
import ImgBallOffline from "../visuals/images/ball-offline.png";
import ImgBallOffline_mobile from "../visuals/images/ball-offline-mobile.webp";
import ImgBallSecurity from "../visuals/images/ball-security.png";
import ImgBallSecurity_mobile from "../visuals/images/ball-security-mobile.webp";
import improvementLogo1 from "../visuals/images/improvement-logo1.svg";
import improvementLogo2 from "../visuals/images/improvement-logo2.svg";
import improvementLogo3 from "../visuals/images/improvement-logo3.svg";
import improvementLogo4 from "../visuals/images/improvement-logo4.svg";
import improvementLogo5 from "../visuals/images/improvement-logo5.svg";
import improvementLogo6 from "../visuals/images/improvement-logo6.svg";

//icons
import { IoFlashOutline, IoHeartOutline, IoShieldCheckmarkOutline, IoRocketOutline, IoFlaskSharp, IoConstructOutline, IoGitBranchOutline, IoPhonePortraitOutline } from "react-icons/io5";
import { BiSearchAlt2, BiBrain, BiLineChartDown,BiBookContent, BiCommentDetail, BiMailSend, BiEdit, BiRightArrowAlt } from "react-icons/bi";
import { GoGear } from "react-icons/go";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaRegEnvelope, FaRegComments, FaRegMoneyBillAlt } from "react-icons/fa";
import { HiOutlinePhone, HiArrowCircleRight } from "react-icons/hi";
import { BsHouse } from "react-icons/bs";
import { RiContactsLine, RiShoppingBasket2Line, RiUserHeartLine } from "react-icons/ri";
import { BsCreditCard, BsUnlock } from "react-icons/bs";
import { GrBitcoin } from "react-icons/gr";
import { IoIosArrowDropdown } from "react-icons/io";
import { ImExit } from "react-icons/im";
import { FiCheckCircle } from "react-icons/fi";

//event / onClick; show more
const handleEvent_improvements = function() {
    document.querySelector('.improvements').classList.add('improvements_unfolded')
}

//data - chart; article: speed
const data_conversions = [
    { x: 1, y: 9 },
    { x: 2, y: 3.5 },
    { x: 3, y: 2 },
    { x: 4, y: 1.25 },
    { x: 5, y: 1 },
    { x: 6, y: 1 },
    { x: 7, y: 0.9 },
    { x: 8, y: 0.9 },
    { x: 9, y: 0.8 }
];

let content_data;
export default content_data = {
    "/": {
        header: {
			hint: "",
			heading: <React.Fragment><span className="halo_left">Web </span><span className="halo_blue">App</span><span className="halo_right">lication Development</span></React.Fragment>,
			lead: "With the help of cutting-edge technologies, we maximize the benefits of website for your business.",
			btn: {
				label: "Show the benefits",
				link: "/technologies/"
			}
		},
        ourSkills:  
            [   
                {
                  theme: 'red',
                  icon: (<IoHeartOutline size={35}/>),
                  title_color: 'Architecture and design',
                  title_main: 'Websites which visitors love.',
                  text: (<React.Fragment>We know how to <Link to="/architecture-and-design/">maximize visitor engagement</Link> and we can prepare a pleasant experience from using the web for them. Keeping a visitors on the site and getting them back is an art that combines many factors.</React.Fragment>),
                  text_secondary: '',
                  text_link: 'How we do it',
                  link_href: '/architecture-and-design/',
                  img: ImgBallUx,
                  img_mobile: ImgBallUx_mobile,
                },{
                  theme: 'yellow',
                  icon: (<IoFlashOutline size={30}/>),
                  title_color: 'Web speed',
                  title_main: 'Lightning fast.',
                  text: (<React.Fragment><Link to="/speed/">Countless studies</Link> shows the tremendous effect of load speed on conversion - to put it otherwise, the speed is money. Thanks to extensive optimizations and state-of-the-art technologies, our pages load in a fraction of standard time.</React.Fragment>),
                  text_secondary: '',
                  text_link: 'The benefits of speed',
                  link_href: '/speed/',
                  img: ImgBallSpeed,
                  img_mobile: ImgBallSpeed_mobile,
                },{
                  theme: 'green',
                  icon: (<IoShieldCheckmarkOutline size={35}/>),
                  title_color: 'Security',
                  title_main: 'Protection from hackers.',
                  text: (<React.Fragment>We are familiar with web vulnerabilities and we know how to prevent them. Cybercrime is <Link to="/security/">growing exponentially</Link> every year, and risk-reduction becoming increasingly important.</React.Fragment>), //Na rozdíl od konkurence nepoužíváme děravé platformy postavené na PHP
                  text_secondary: '',
                  text_link: 'Find out more',
                  link_href: '/security/',
                  img: ImgBallSecurity,
                  img_mobile: ImgBallSecurity_mobile,
                },{
                  theme: 'blue',
                  icon: (<IoRocketOutline size={30}/>),
                  title_color: 'Cutting-edge technology',
                  title_main: 'Surfing offline? Why not.',
                  text: (<React.Fragment>Websites created by us are up and running, including interactions, even when the user has just entered the elevator or when user's connection on the train isn't stable. And the latest technologies offers <Link to="/technologies/">much more</Link>, such as notifications or the ability to install directly on the device's homescrean.</React.Fragment>), //Vaše webové stránky jsou tak vždy dostupné a mile překvapí návštěvníky. 
                  text_secondary: '',
                  text_link: 'How is it possible',
                  link_href: '/technologies/',
                  img: ImgBallOffline,
                  img_mobile: ImgBallOffline_mobile,
                },
            ],
        contactUs: {
            heading: 'Contact us',
            hint: 'Three ways to contact us.',
            methods: [
                {
                    theme: 'blue',
                    icon: <FaRegEnvelope />,
                    href_method: 'mailto',
                    href_title: 'click here to write an email',
                    value: 'webolo@webolo.eu',
                    hint: '',
                },
                {
                    theme: 'green',
                    icon: <HiOutlinePhone />,
                    href_method: 'tel',
                    href_title: 'click to dial the number',
                    value: '+420 774 981 316',
                    hint: 'Mon-Fri 8:00-17:00 CET',
                },
                {
                    theme: 'indigo',
                    icon: <FaRegComments />,
                    href_method: '',
                    value: 'On-line chat',
                    hint: 'In the bottom right corner',
                },
            ],
        },
        whatWeDo: {
            heading: 'What we do',
            hint: 'The three main categories of services we offer.',
            btn: 'Send inquiry',
            services: [
                {
                    name: 'Web development',
                    dscr: <React.Fragment>Web application with the possibility of a&nbsp;version for AppStore and GooglePlay or also a&nbsp;fine tuned presentation tailored to your needs. With an&nbsp;emphasis on UX design, speed and security.</React.Fragment>,
                    icon: <AiOutlineGlobal />,
                    theme: 'green',
                    title_hint: '☰ SHOW MENU',
                },
                {
                    name: 'Tools of automation',
                    dscr: <React.Fragment>By automating tasks and data processing, you can save a lot of hours of human labor, and therefore money, and yet at the same time also gain consistency.</React.Fragment>,
                    icon: <GoGear />,
                    theme: 'yellow',
                    title_hint: '☰ SHOW MENU',
                },
                {
                    name: 'Solution audit',
                    dscr: <React.Fragment>Thorough audit of zour current solution. Find out where the weak spots are and how to achieve better results. Security, architecture, speed - three aspects that we will thoroughly examine and suggest how to increase the performance of your website.</React.Fragment>,
                    icon: <BiSearchAlt2 />,
                    theme: 'indigo',
                    title_hint: '☰ SHOW MENU',
                }
            ]
        },
        mainAnimation: {
            heading: 'Websites ready for the future',
            content: <React.Fragment>Be ahead and invest in technologies that your competitors don't know about yet. Most customers today consume the web via mobile devices that offer many technologies that benefit your business.</React.Fragment>,
            btn: 'Find out more',
        },
        tileGrid: {
            theme: '',
            heading: 'Join those who have already discovered the benefits of these technologies:',
            paragraph: '',
            btn_more: 'Show more',
            btn_cta: 'More about these studies',
            tiles: [
                {
                    icon: <img src={improvementLogo1} loading="lazy" decoding="async" alt="[logo] Forbes" />,
                    content: <React.Fragment><span>43%</span> longer<br />time spent on the web</React.Fragment>,
                    hint: 'Forbes (magazine, US)',
                },
                {
                    icon: <img src={improvementLogo2} loading="lazy" decoding="async" alt="[logo] George" />,
                    content: <React.Fragment><span>31%</span> increase in<br /> mobile conversions</React.Fragment>,
                    hint: 'George (clothing, UK)',
                },
                {
                    icon: <img src={improvementLogo3} loading="lazy" decoding="async" alt="[logo] Lancôme" />,
                    content: <React.Fragment><span>17%</span> increasing in<br /> conversions</React.Fragment>,
                    hint: 'Lancôme (cosmetics, FR)',
                },
                {
                    icon: <img src={improvementLogo4} loading="lazy" decoding="async" alt="[logo] Twitter" />,
                    content: <React.Fragment><span>65%</span> increase in<br /> pages viewed per visit</React.Fragment>,
                    hint: 'Twitter (social media, US)',
                },
                {
                    icon: <img src={improvementLogo5} loading="lazy" decoding="async" alt="[logo] Corel" />,
                    content: <React.Fragment><span>2.5x</span> increas in<br /> the chance of conversion</React.Fragment>,
                    hint: 'Corel (software, US)',
                },
                {
                    icon: <img src={improvementLogo6} loading="lazy" decoding="async" alt="[logo] Trivago" />,
                    content: <React.Fragment><span>97%</span> increase in<br /> mobile conversions</React.Fragment>,
                    hint: 'Trivago (accommodations, US)',
                },
            ]
        }
    },
    "/references/": {
        header: {
			hint: "",
			heading: 'Nothing in here',
			lead: "Just beggining.",
			btn: '',
		},
    },
    "/inquiry/": {
        menu: {
            heading: 'Select the service you are interested in:',
            hint: <React.Fragment>You can send the request by e-mail to <a href="mailto:inquiry@webolo.eu">inquiry@webolo.eu</a>, or by filling out the form below.</React.Fragment>,
        },
        form: {
            validation: {
                email: 'Does not match the format of the email address.',
                required: 'Email is required.'
            },
            inputs: {
                heading_outline: `Solution's outline`,
                heading_contact: `Contact information`,
                heading_contact_hint: `Information about your company`,
                label_service: [
                    'Type of solution',
                    undefined,
                    'Required audit areas',
                ],
                services: [
                    [{
                        name: 'Web presentation',
                        id: 'web',
                    },
                    {
                        name: 'Web application',
                        id: 'webapp',
                    },
                    {
                        name: 'Web application with version for AppStore/Google Play',
                        id: 'webapp+native',
                    }],
                    undefined,
                    [{
                        name: 'Security',
                        id: 'security',
                    },
                    {
                        name: 'Performance',
                        id: 'performance',
                    },
                    {
                        name: 'UX design',
                        id: 'ux',
                    }],
                ],
                rfq_audit_subject: {
                    label: 'Audited website',
                    hint: 'One or more audit subject addresses.',
                },
                rfq_deadline: {
                    label: 'Required delivery date',
                    hint: 'The date when you would need to have the solution up and running.',
                    month_aria: 'Required delivery date - month',
                    month_init: 'month',
                    year_aria: 'Required delivery date - year',
                },
                rfq_description: {
                    label: 'Description of purpose and goals',
                },
                rfq_budget: {
                    label: 'Rought budget',
                    hint: 'Your estimate of the gross project budget.',
                    currency: 'EUR',
                },
                rfq_other: {
                    label: 'Further details and requirements',
                },
                client_company: {
                    label: 'Company',
                    hint: 'Name, ID number, VAT number, web - as you wish.',
                },
                client_email: {
                    label: 'E-mail address',
                    hint: 'Your work email.',
                },
                client_tel: {
                    label: 'Telephone number',
                    hint: 'Your telephone number.',
                },
                submit_btn: {
                    label: 'Send inquiry',
                    status: 'Sending',
                    warning: 'Some data needs to be checked.',
                }

            },
            success: <React.Fragment>
                <div className="form_sent">
                    <div className="status">
                        <FiCheckCircle />
                    </div>

                    <h2>The request has been received</h2>
                    <p>As soon as we start preparing the offer, we will send you a notification message.</p>

                    <Link to="/">
                        <div className="btn btn-primary">
                            Back to Homepage <HiArrowCircleRight />
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        }
    },
    articles: {
            "/architecture-and-design/": {
                header: {
                    hint: "",
                    heading: "Visitor satisfaction",
                    lead: "Web architecture, user exterience design and fast application are key pillars of visitor satisfaction, which is reflected not only in conversions, but also in the repetition, frequency and intensity of using your service.",
                    btn: ""
                },
                article_content: 
                <React.Fragment>
                    <div class="article">
                        <div class="article_columns">
                            <div>

                                    <div class="icon theme-green">
                                        <FaRegMoneyBillAlt size={35}/>
                                    </div>
                                    <h2>The economic dimension of design</h2>

                                <p>Ergonomics in the form of applied psychology and best prectices largely determines the success of the website. Just as building architect needs to take into account the behavior, habits, needs and values of people using a particular building, we <span class="highlight">design a website with an emphasis on ergonomics and intuitive use</span>. Often it can be about rather subtle things, which makes the difference. <span class="highlight">Like even just changing color of a button can significantly influence the size of your earnings</span>.</p>

                                    <div class="icon theme-indigo">
                                        <BsHouse size={35}/>
                                    </div>
                                    <h2>Web architecture</h2>

                                <p>Website architecture built with an emphasis on user experience maximizes the potential of the website. It is an interdisciplinary craft involving applied prychology, graphic design and technical knowledge and makes the basis for good user experience.</p>
                                    
                                <p>One additional benefit of well designed website is actual ability to profile your customers and get waluable data from their interaction with the site. Turning your site into a customer survey in a way creates additional benefit and <span class="highlight">giving you double value from the website</ span>.</p>
                        
                                <div class="icon theme-yellow">
                                    <IoConstructOutline size={35}/>
                                </div>
                                <h2>Technical side</h2>

                                    <p>The basic necessity is to prepare for the visitor a frustration-free experience. Unfortunately, even in this, the most websites falter. One of important aspects here is the <span class="highlight">loading and rendering speed</span>of the site.</p>
                            </div>
                            <div>	

                                <div class="icon theme-red">
                                    <BiBrain size={35}/>
                                </div>
                                <h2>Psychology of visitors</h2>

                                <p>We consider the typical patterns of behavior and the ways we process information, as well as the visitor's emotional dimension and responses to various stimuli.</p>

                                <p>Esential is to see the site from the visitor's perspective. That also means to supplement it with useful information so that he has the necessary feedback and to ensure that his journey through the website to the goal/conversion is withou any obstacles.</p>

                                <p><span class="highlight">Satisfying visitor's motivation is the key</span>. We know how to maximize this dimension and what information users use to evaluate quality.</p>

                                <div class="icon theme-blue">
                                    <IoFlaskSharp size={35}/>
                                </div>
                                    <h2>Testing</h2>

                                    <p>The composition of visitors varies greatly from site to site, and the only way to design a truly effective design is to observe and evaluate visitor behavior.</p>

                                    <p>In addition to analyzing basic data from web traffic, another very effective tool to compare effect of two simultaneously run variants of a same thing. During such A/B testing, part of visitors will see a slightly different look of a site and according to the data from observing a sufficient sample of visitors, it is possible to evaluate the impact of the adjustment in comparison with the original state. This way its possible to <span class="highlight">gradually increase the conversion rate</span>.</p>

                                    <p>We work with data carefully. Not everything which can be counted is data. Metrics are often misleading unless interpreted in a carefully considered context.</p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                },
                
                "/security/": {
                    header: {
                        hint: "",
                        heading: "Data protection and reputation",
                        lead: "55% of all cyber-attacks targeted web services last year. Simultaneously, the number of attacks grows exponentially every year, and thanks to automated attacks, even small targets fall prey to hackers.",
                        btn: ""
                    },
                    article_content: 
                    <React.Fragment>
                        <div class="chart chart_centered">
                            <VictoryContainer width={400} height={400}>
                                <svg viewBox="0 0 400 400">
                                <VictoryPie
                                    standalone={false}
                                    width={400} height={400}
                                    data={[
                                    { x: 1, y: 25.6, fill: "red", name: "automated attacks" }, { x: 2, y: 15.2, fill: "#ccc", name: "search engine automation etc." }, { x: 3, y: 59.2, fill: "#efefef", name: "lidé" }
                                    ]}
                                    innerRadius={93} labelRadius={100}
                                    style={{ labels: { fontSize: 30 }, data: { fill: (d) => d.datum.fill }}}
                                    labelComponent={<VictoryTooltip/>}
                                    labels={({ datum }) => `${datum.name}: ${datum.y} %`}
                                />
                                <VictoryLabel
                                    textAnchor="middle"
                                    style={{ fontSize: 72 }}
                                    x={200} y={200}
                                    text="25.6%"
                                />
                                </svg>
                            </VictoryContainer>
                            <div class="chart_external_label">Internet traffic consists<br />of automated attacks</div>
                        </div>

                        <div class="describtion">Source: Internet Security Report 2021, Impreva, 2021.</div>

                        <div class="article">
                            <div class="article_columns">
                                <div>

                                <p>Interent is becoming an increasingly important part of our society and economies. Technological progress and the transfer of all parts of society from business and goverment agencies through healthcare to finance to the Internet, is a powerful engine of cybercrime.</p>

                                <p>In addition to that, the relative impunity for offenders further fuels the trend. And the cyber crime is sadly also faily easy business - great deal of websites contains amateurish security vulnerabilities that can be exploited virtually by anyone after watching a few videos on the YouTube.</p>

                                <div class="quote">
                                    Only 3 out of 1000 <u>detected</u> cybercrimes are punished, which in turn translates to a estimated probability of some 0.00001%.
                                </div>

                                <p>For these reasons, websites are an easy and frequent prey to the hackers. Small website isnt valuable target by itself, but in bulk small websites offers easy target with weak security and contains a lot of valuable data such as user data (you typically get about 0.6 EUR/pc per e-mail on the black market), but websites can also be misused for other purposes such as spying on users, spreading spam or serving as a base for DDos attacks on other sites.</p>

                                </div>
                                <div>   

                                <p>The attack, in addition to material damage, is often capable of causing considerable damage to the reputation and outflow of customers, as some of e-shops have experienced.</p>

                                <p>Often, the attacker does not have to be a professional or an organized group, but it can easily be a student who takes it as a amusement. Most often, however, it is an automated malware that gets into your system due to insufficient security measures at the architectural level. The second most common intruder of smaller projects are automated robots scanning the Internet and looking for weaknesses.</p>
                                
                                <p>As soon as they find a weakness, they immediately exploit it or notify the operator for potential later targeted attack.</p>

                                </div>
                            </div>

                            <TileGrid content={{
                                theme: 'red',
                                type: 'casestudy',
                                heading: 'Ways of data misuse and access',
                                paragraph: [
                                    <React.Fragment>The damage is typically caused by the misuse of data for further attacks or their sale, but there is also misuse of your resources for mining of cryptocurrencies or the sending of SPAM.</React.Fragment>,
                                    <React.Fragment>The overview below summarizes the most common purposes of attacks:</React.Fragment>,
                                ],
                                btn_more: 'Show more',
                                btn_cta: '',
                                tiles: [
                                    {
                                        icon: <RiContactsLine size={50} />,
                                        content: 'theft of user data and their sale',
                                        hint: '',
                                    },
                                    {
                                        icon: <BsCreditCard size={50} />,
                                        content: 'unauthorized card payments',
                                        hint: '',
                                    },
                                    {
                                        icon: <BsUnlock size={50} />,
                                        content: 'interception of login data',
                                        hint: '',
                                    },
                                    {
                                        icon: <BiLineChartDown size={50} />,
                                        content: 'deterioration of search engine rankings',
                                        hint: '',
                                    },
                                    {
                                        icon: <BiEdit size={50} />,
                                        content: 'fake content and replacing ads',
                                        hint: '',
                                    },
                                    {
                                        icon: <GrBitcoin size={50} />,
                                        content: 'cryptocurrency mining',
                                        hint: '',
                                    },
                                    {
                                        icon: <BiMailSend size={50} />,
                                        content: 'sending of SPAM',
                                        hint: '',
                                    },
                                ]
                            }}/>

                            <div class="article_columns">
                                <div>
                                <h2>How to protect yourself</h2>

                                <p>In contrast to dealing with a damage caused by an attack, protection and prevention are usually fairly cheap and investment in it plays an imaginary role of an insurance.</p>

                                <p>Attacks take place both on the technical side of things and on the human factor, for example, typically in the form of fraudulent e-mails, etc. There are a number of measures against both types at the level of site architecture and program code to help prevent them.</p>

                                <p>In addition to the work of a developer, maintenance is also important - keeping the individual components of the website updated and testing the security of the website periodically. In addition to new features and enhancements, the update also brings security patches to the known vulnerabilities that are often <a href="https://cve.mitre.org/">publicly available</a> on the web. An out-of-date version of a component thus represents an easy-to-exploit vulnerability.</p>

                                <p>For card payment sites, we recommend quarterly audits in accordance with <a href="https://www.pcisecuritystandards.org/document_library">PCI standards</a> and regular malware monitoring.</p>

                                <p>In addition to knowing how to write secure code and principles of designing a secure application to minimize the likelihood of an attacker breaking into the system, we check the security of our applications by penetration tests before handing them over to the client.</p>

                                </div>
                                <div>   

                                <h2>Legal dimension</h2>  

                                <p>With the advent of GDPR, users whose data leaked or were misused were given an effective tool to enforce compensation. In the EU, it is typically a 1 000 up to 60 000 EUR.</p>

                                <p>Specifically, the GDPR states the obligation to adequately protect the data, and also to monitor and report major incidents.</p>

                                <p>Thanks to a number of services that monitor data leaks, such as that one which is part of the iPhone operating system, users are increasingly learning about a data leaks in short time after a incident occurt. That brings the increased risk of lawsuits.</p>

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                },
                "/speed/": {
                    header: {
                        hint: "",
                        heading: "Speed = conversions",
                        lead: "Even a small improvement in website speed has a positive effect on business results.",
                        btn: ""
                    },
                    article_content: 
                    <React.Fragment>
                        <div class="chart chart_centered">
                                <div class="chart_external_label">1s of loading represents a loss of</div>
                                <VictoryContainer width={400} height={400}>
                                    <svg viewBox="0 0 400 400">
                                    <VictoryPie
                                        standalone={false}
                                        width={400} height={400}
                                        data={[
                                        { x: 1, y: 20, fill: "rgb(51,193,83)" }, { x: 2, y: 80, fill: "#ccc" }
                                        ]}
                                        innerRadius={85} labelRadius={100}
                                        style={{ labels: { display: "none" }, data: { fill: (d) => d.datum.fill }}}
                                    />
                                    <VictoryLabel
                                        textAnchor="middle"
                                        style={{ fontSize: 75 }}
                                        x={200} y={200}
                                        text="20%"
                                    />
                                    </svg>
                                </VictoryContainer>
                                <div class="chart_external_label">conversions</div>
                            </div>

                            <div class="describtion">Source: SOASTA, The State of Online Retail Performance, U.S., 2017.</div>

                            <div class="article">
                                <div class="article_columns">
                                    <div>

                                    <h2>The real cost of speed</h2>

                                    <p>The speed of page loading and rendering determines the rate of conversions and other metrics. It has a direct as well as indirectly impact. The indirect one is that <mark>speed actualy allows other investments to be effective</mark>. Its for instance estimated, that can easily save up to 20% on digital marketing funds. The acceleration of your website thus has a positive effect both on the profit of the project, and also reduces wasted investmens.</p>

                                    <p>With a slow-loading page, it doesn't matter how much money you put in ads, how good design and features of your site are, or how well curated content you have. All this is simply not important when the visitor is leaving.</p>
                                    
                                    <p>However, the most significant and best studied is the effect of speed on conversions. Countless studies show how merely a <mark>fractions of seconds drastically reduce conversions</mark>, cause visitor loss, and negatively affect behavior of visitors, who stays - eg. pages viewed or time spent on page. It is in your best interest to design and optimize the website accordingly to achieve the shortest possible loading and rendering time.</p>

                                    <div class="quote">
                                        According to Amazon, every 100ms of response time results in 1% loss in sales (1.3 billion USD).
                                    </div>

                                    <p>Last but not least, the speed of your site negatively affects search engine optimization and therefore search position, leading to lower traffic.</p>

                                    </div>
                                    <div>   

                                    <h2>Search engine rankings</h2>

                                    <p>Search engines penalize <mark>slow pages</mark> and the page will appear in lower positions or disapear in other pages of the search results, which few people visit.</p>

                                    <p>Given that search engines are usually the dominant source of visitors, this in itself is a very good argument to deal with speed. Faster speed represents a tool to increase traffic.</p>

                                    <h2>Loss of conversions</h2>

                                    <p>Based on research conducted by Google we know the approximate ratio of rendering speed to conversions, see the chart below.</p> 

                                    <div class="chart_conversion">
                                        <VictoryChart>
                                            <VictoryLine
                                                interpolation="cardinal" data={data_conversions}
                                                style={{ data: { stroke: "#c43a31" } }}
                                            />
                                            <VictoryAxis 
                                                dependentAxis 
                                                tickCount={0} 
                                                tickFormat={(t) => ``} 
                                                label="Conversion rate [%]"
                                            />
                                            <VictoryAxis  
                                                domain={{x: [0, 10]}}
                                                label="Loading time [seconds]"
                                                tickValues={[1,2,3,4,5,6,7,8,9,10]}
                                                style={{ tickLabels: { fill: "#999" } }}
                                            />
                                            <VictoryScatter data={data_conversions}
                                                size={5} 
                                                style={{ data: { fill: "#c43a31" } }}
                                                domain={{
                                                x: [0, 10],
                                                y: [0, 10]
                                                }}
                                            />
                                            </VictoryChart>
                                    </div>
                                        
                                    <p>The most significant effect speed has on mobile device users. As the most visitors comes from mobile devices, it's important to consider focusing on this group of visitors. On mobile, conversions drops by up to 95% for pages that take a few seconds to render.</p>
                                    
                                    </div>
                                </div>

                                <TileGrid content={{
                                    theme: '',
                                    type: 'casestudy',
                                    heading: 'Case study by Deloitte',
                                    heading2: <React.Fragment><h1>0.1s<br />=</h1></React.Fragment>,
                                    paragraph: [
                                        <React.Fragment>In 2020, Deloitte, in collaboration with Google, conducted an extensive study of the impact of site speed on visitor behavior.</React.Fragment>,
                                        <React.Fragment>According to the study, page speed negatively correlates not only with conversion rate but also with number of pages visited and orders size. The report also illustrates growing advantage of pages optimized for fast speed and that this advantage grows on significance with growing popularity of mobile devices.</React.Fragment>,
                                        <React.Fragment>The overview below summarizes several key findings of the study, which demonstrates effect of 0.1s loading time on visitor behavior.</React.Fragment>,
                                    ],
                                    btn_more: 'Show more',
                                    btn_cta: '',
                                    tiles: [
                                        {
                                            icon: <IoGitBranchOutline size={50} />,
                                            content: 'Significant reduction in visitor loss on the path to conversion.',
                                            hint: '',
                                        },
                                        {
                                            icon: <RiShoppingBasket2Line size={50} />,
                                            content: '8.4% increase in conversions and an average increase in order value of 9.2%.',
                                            hint: '',
                                        },
                                        {
                                            icon: <IoPhonePortraitOutline size={50} />,
                                            content: 'Increase in the number of pages visited by the mobile users.',
                                            hint: '',
                                        },
                                        {
                                            icon: <BiRightArrowAlt size={50} />,
                                            content: 'Visitors were most sensitive to speed at the beginning of theirs visit.',
                                            hint: '',
                                        },
                                        {
                                            icon: <ImExit size={50} />,
                                            content: 'Average reduction 5.8% of visitors leaving the landing page.',
                                            hint: '',
                                        },
                                        {
                                            icon: <RiUserHeartLine size={50} />,
                                            content: 'Increase in engagement by 5.2%. ads for theirs',
                                            hint: '',
                                        },
                                    ]
                                }}/>

                                <div class="article_columns">
                                    <div>
                                    <h2>Real-world examples</h2>

                                    <p>Large platforms and e-commerce players are well aware of the importance of speed, and as a result, there are many well-documented measurements of the link between loading speed and business results. Below are some of them:</p>

                                    <p>Dakine, a retail clothing company, has published the effect of accelerating their German e-shop. After the revision of the website, they achieved an acceleration of 48-65% depending on the particular page (homepage, product page, category, etc.), which after the evaluation next year reportedly brought an increase in revenues from mobile conversions by 45%.</p>

                                    <p>By reducing load time by 38%, Pfizer increased conversions by 9% and reduced first-page bounce rate by 20%.</p>

                                    <p>BMW's redesign of their website, with loading speed prioritization, increased product page visits from 8% to 30%.</p>

                                    <p>In 2019, eBay stated that an acceleration of 0.1s corresponds to an increase in the total number of products added to the cart by all users by 0.5%.</p>
                                    </div>
                                    <div>   

                                    <h2>Technical side of things</h2>  

                                    <p>Page speed is affected by a host of factors, and aside from the basic ones, such as the infrastructure on which the website runs, is literally an alchemy of the right technical solution and the tweaking of countless small aspects. In principle, it can be divided into three aspects - server response, content loading and content rendering. Each aspect is affected by different factors.</p>

                                    <p>On average, it takes 87.84% longer to load a web page on a phone than on a desktop. Most Internet content today is consumed through mobile devices, and many developers dont take into account many variable such as variable connection speed, and that most of the population has less powerful phones where it is not just about loading speed but also rendering of the page.</p>

                                    <p>The greatest potential is lost on mobile devices, which, as it has already been mentioned, are often forgotten in terms of speed during development. Interesting fact is that although most visits come from mobile devices today, desktops and laptops have a higher conversion rate for slow sites. Such site loses a substantial part of its customers. Mobile users are significantly less willing to wait for a slow website - if the loading time goes from 1 second to 3, the probability of a visitor leaving will increase by 32%.</p>

                                    <p>We are aware of the great importance of page loading speed and therefore we not only focus on it so that we deliver a solution that loads at lightning speed, but also after handing over the project to the owner we usually monitor the loading speed and notify you if the loading speed significantly change to the worse.</p>

                                    <p>One good news is - the most of the research that has been conducted has focused on visitors from the United States and other data shows that for example here in Europe visitors are a few hundred milliseconds more patient. However, comparable behavior occurs, albeit a little bit later.</p>
                                    </div>
                                </div>
                            </div>
                    </React.Fragment>
                },
                "/technologies/": {
                    header: {
                        hint: "",
                        heading: "The latest technology",
                        lead: "New web technologies open door to previously unreachable opportunities to better serve and engage visitors and thus support your business results.",
                        btn: ""
                    },
                    article_content: 
                    <React.Fragment>
                        <div class="chart chart_centered">
                            <div class="chart_external_label">1s of loading represents a loss of</div>
                            <VictoryContainer width={400} height={400}>
                                <svg viewBox="0 0 400 400">
                                <VictoryPie
                                    standalone={false}
                                    width={400} height={400}
                                    data={[
                                    { x: 1, y: 20, fill: "rgb(51,193,83)" }, { x: 2, y: 80, fill: "#ccc" }
                                    ]}
                                    innerRadius={85} labelRadius={100}
                                    style={{ labels: { display: "none" }, data: { fill: (d) => d.datum.fill }}}
                                />
                                <VictoryLabel
                                    textAnchor="middle"
                                    style={{ fontSize: 75 }}
                                    x={200} y={200}
                                    text="20%"
                                />
                                </svg>
                            </VictoryContainer>
                            <div class="chart_external_label">conversions</div>
                        </div>

                        <div class="describtion">Source: SOASTA, The State of Online Retail Performance, U.S., 2017.</div>

                        <div class="article">
                            <div class="article_columns">
                                <div>

                                <h2>Web as an application</h2>

                                <p>Many of the mobile applications that we commonly use today, whether Airbnb, Facebook or Instagram, are no longer native applications, but technically a website. This is made possible by new technologies that blur the distinction between the web and applications, and their implementation offers a number of benefits for your business.</p>

                                <p>As a rule, these technologies improve the experience for the visitor and better engage him. This then brings positives to your business results.</p>

                                <p>The other side of the matter is a very significant reduction in investment and acceleration of development if you decide to offer your application across platforms - Android, iOS, etc.</p>

                                <div class="quote">
                                    Thanks to speed and notifications, you will increase customer commitment and loyalty.
                                </div>

                                <p>Countless examples from practice and studies conducted in recent years show that the application of these new technologies unlocks previously unavailable potential and has a significant positive effect on conversions as well as other important web metrics.</p>
                                
                                </div>
                                <div>   

                                <h2>Benefits of web applications</h2>

                                <p>Compared to traditional websites and native applications, web applications offer a number of advantages. In competition with regular websites, they excel in meeting customer needs (see <Link to="#customer-needs">below</Link>), which is positively reflected in business results and, compared to native applications, web applications excel in cost savings and availability across devices.</p>

                                <p>The main metrics of your site will most significantly and directly improve speed, which is an <Link to = "/ speed /"> key parameter determining your business results </Link>. Browsing and retrieving web applications is lightning fast, which has a significant impact on engagement and conversion, but also page bounce rates, number of pages visited, and visitor loyalty.</p>

                                <p>The pillar of this is the advanced algorithmic storage and use of read data in such a way that the website will run for the visitor, even when he is offline, including most of the sites he has not yet visited. In addition, actions taken such as adding a rating will not be lost, but will be performed as soon as the user is back online, even if their site is still open..</p> 

                                <p>We create websites using the most modern technologies, which allow us to prepare timeless pages with functions and features that will be widespread in the coming years..</p>

                                </div>
                            </div>


                            <TileGrid content={{
                                    theme: 'blue',
                                    type: 'casestudy',
                                    heading:    <React.Fragment>Case study by&nbsp;
                                                    <span class="google_blue">G</span>
                                                    <span class="google_red">o</span>
                                                    <span class="google_yellow">o</span>
                                                    <span class="google_blue">g</span>
                                                    <span class="google_green">l</span>
                                                    <span class="google_red">e</span>
                                                </React.Fragment>,
                                    heading2: <React.Fragment><h3 class="theme-blue">The benefits of implementing application technologies on the web:</h3></React.Fragment>,
                                    paragraph: [
                                        <React.Fragment>In 2017-2021, Google conducted several studies to monitor the deployment of application technologies on the Web.</React.Fragment>,
                                        <React.Fragment>The study was conducted across disciplines and continents of regional and global players and shows a clear positive impact of the implementation of these technologies.</React.Fragment>,
                                        <React.Fragment>Below is a summary of a few facts from those studies.</React.Fragment>,
                                    ],
                                    btn_more: 'Show more',
                                    btn_cta: '',
                                    tiles: [
                                        {
                                            icon: <img src={improvementLogo1} loading="lazy" decoding="async" alt="[logo] Forbes" />,
                                            content: <React.Fragment><span>43%</span> longer<br />time spent on the web</React.Fragment>,
                                            hint: 'Forbes (magazine, US)',
                                        },
                                        {
                                            icon: <img src={improvementLogo2} loading="lazy" decoding="async" alt="[logo] George" />,
                                            content: <React.Fragment><span>31%</span> increase in<br /> mobile conversions</React.Fragment>,
                                            hint: 'George (clothing, UK)',
                                        },
                                        {
                                            icon: <img src={improvementLogo3} loading="lazy" decoding="async" alt="[logo] Lancôme" />,
                                            content: <React.Fragment><span>17%</span> increasing in<br /> conversions</React.Fragment>,
                                            hint: 'Lancôme (cosmetics, FR)',
                                        },
                                        {
                                            icon: <img src={improvementLogo4} loading="lazy" decoding="async" alt="[logo] Twitter" />,
                                            content: <React.Fragment><span>65%</span> increase in<br /> pages viewed per visit</React.Fragment>,
                                            hint: 'Twitter (social media, US)',
                                        },
                                        {
                                            icon: <img src={improvementLogo5} loading="lazy" decoding="async" alt="[logo] Corel" />,
                                            content: <React.Fragment><span>2.5x</span> increas in<br /> the chance of conversion</React.Fragment>,
                                            hint: 'Corel (software, US)',
                                        },
                                        {
                                            icon: <img src={improvementLogo6} loading="lazy" decoding="async" alt="[logo] Trivago" />,
                                            content: <React.Fragment><span>97%</span> increase in<br /> mobile conversions</React.Fragment>,
                                            hint: 'Trivago (accommodations, US)',
                                        },
                                    ]
                                }}/>

                            <div class="article_columns">
                                <div>
                                <h2 id="zakaznice-potreby">Fulfilling customer needs</h2>
                                
                                <p><strong>Speed</strong> - customers have high demands on web performance today. Web applications are significantly faster than regular websites.</p>

                                <p><strong>Reliability</strong> - web applications also work offline, even days after the last visit, and synchronize user actions in such a way that even actions taken offline are not lost.</p>

                                <p><strong>Availability</strong> - web applications are accessible across platforms and the visitor can install them on the desktop of the device as a regular application, which will always be easily available.</p>

                                <p><strong>Commitment</strong> - web applications increase visitor engagement.</p>
                                
                                </div>
                                <div>   

                                <h2>Internal company applications</h2>  

                                <p>Web applications are an ideal form of internal automation and registration tools because, unlike other solutions, they are the only ones that meet cross-platform availability (iOS, Android, PC), reliability and save development costs and time, because you can use one code across devices.</p>

                                <p>In addition, you can take full advantage of the capabilities of mobile devices and enrich automation with a number of functions based on sensors (light, position, movement, barometer, etc.) and other functions (camera, flashlight, bluetooth, VR and more).</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }         
    },
    articles_menu: {
        heading: 'More on how we create',
    }
};