import * as React from "react";
import { Link } from "gatsby";

export default function LayoutHeader ( {content, location} ) {

	const pathname = location.pathname;
	const localContent = content[pathname]?.header ?? content.articles[pathname]?.header;

	if(localContent)
	{
		return(
			<div className="container container_header">
				<header>

					<div id="halo">
						<h1>{localContent?.heading ?? ""}</h1>
						<div className="lead">
							{localContent?.lead ?? `fuckin' what?`}
						</div>
						
						{localContent?.btn && <div className="btn btn-primary">
							<Link to={localContent?.btn.link}>{localContent?.btn.label}</Link>
						</div>}
					</div>

				</header>
			</div>
		);
	}

	return null;
}