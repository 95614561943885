import React, { useLayoutEffect } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet'

//conponents
import LayoutHeader from "../components/layout_header.js";
import Nav from "../components/layout_nav.js";
import LayoutFooter from "../components/layout_footer.js";

//content
import contentData from "../content/content-data.json";
import contentData_js from "../content/content-data.js";

//visuals
import '../visuals/index.scss';
import '../visuals/bootstrap.css';
import bgIconSvg_def from "../visuals/images/bg_icon.svg";
import bgIconSvg_heart from "../visuals/images/bg_heart.svg";
import bgIconSvg_flash from "../visuals/images/bg_flash.svg";
import bgIconSvg_racket from "../visuals/images/bg_racket.svg";
import bgIconSvg_shield from "../visuals/images/bg_shield.svg";

export default function Layout ({ children, location }) {

	const locationSubstr = location.pathname.replace(/\//g,'');

	const bgIcon = function(locationPathname) {

		const dict = {
			'/architecture-and-design/': bgIconSvg_heart,
			'/speed/': bgIconSvg_flash, 
			'/security/': bgIconSvg_shield, 
			'/technologies/': bgIconSvg_racket, 
		}

		return dict[locationPathname] ?? bgIconSvg_def
	}

	const headerInViewport = function (entries) {

		entries.forEach(entry => {
			if(entry.target.localName === 'header') {
				const bubbles_wrapper = document.querySelector('.bubbles');
				const allBubbles = document.querySelectorAll('.bubble');
				let isInViewport = entry.isIntersecting;

				allBubbles.forEach( (bubble) => {
					setTimeout(() => {
						isInViewport ? bubble.classList.remove('bubble_paused') : bubble.classList.add('bubble_paused');
					}, 300);
				});

				if(bubbles_wrapper) {
					setTimeout(() => {
						isInViewport && bubbles_wrapper.classList.add('bubbles_visible');
					}, 100);	
				}
			}
		});
	}

	const initiateObserver_Header = function() {
		let element_header = document.querySelector("header");

		if(element_header) {
			let options = {
				root: null,
				rootMargin: "0px",
				threshold: 0,
			};
			
			let observer = new IntersectionObserver(headerInViewport, options);
			observer.observe(element_header);
		}
	}

	useLayoutEffect(() => {

		initiateObserver_Header();

		setTimeout(() => {
			let element = document.querySelector('#main_content');
			element && element.classList.remove('let_it_be');
		}, 300);

		setTimeout(() => {
			let smartsuppScript = document.createElement('script');
			smartsuppScript.src = '/smartsupp.js';
			document.head.appendChild(smartsuppScript);
		}, 5000);
	}, []);

	const setClasses = function(classes) {
		return Object
		.entries(classes)
		.filter(pair => pair[1])
		.map(pair => pair[0])
		.join(' ');
	};

	return (
	<React.Fragment>

		<div className="application">
			<Helmet>
				<meta charSet="utf-8" />
				<title>{process.env.WEBSITE_TITLE}</title>
				<meta name="description" content={process.env.WEBSITE_DESCRIPTION} />
				<link rel="canonical" href={process.env.WEBSITE_ADDR} />
			</Helmet>
		</div>

		{(location.pathname !== "/inquiry/") &&
			<div className={setClasses({
					bubbles: true,
					[`bubbles_${locationSubstr}`]: locationSubstr.length > 2
				 })
			}>
				<div className="bubble bubble_paused bubble_st">&nbsp;</div>
				<div className="bubble bubble_paused bubble_nd">&nbsp;</div>
				<div className="bubble bubble_paused bubble_rd">&nbsp;</div>	
			</div>
		}

		<div className={setClasses({
				glass: true,
				[`glass_${locationSubstr}`]: locationSubstr.length > 2
			})}
			style={{backgroundImage: `url("${bgIcon(location.pathname)}`}
		}>
			<Nav content={contentData} location={location} />

			<div className="nav-brand">
				<div className="container">
					<Link to="/">{process.env.WEBSITE_APPNAME}</Link>
				</div>
			</div>

			<LayoutHeader content={contentData_js} location={location} />
			
			<div id="main_content" className="container let_it_be">
				{children}
			</div>
		</div>
		<LayoutFooter />

	</React.Fragment>
	);
}