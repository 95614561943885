import * as React from "react";
import { Link } from "gatsby";

import { IoIosArrowDropdown } from "react-icons/io";

export default function TileGrid ( props ) {

    const handleEvent_improvements = function() {
        document.querySelector('.improvements').classList.add('improvements_unfolded')
    }

    return(
        <div className={`joinUs ${props.content.type ?? ``}`}>
              
            <h2 className={props.content.theme ? `theme-${props.content.theme}` : ``}>{props.content.heading}</h2>

            {props.content.paragraph && props.content.paragraph.map( (paragraph, index) => (
                <div className={(index === 0) ? `lead` : ``}>{paragraph}</div>
            ))}

            {props.content.heading2 && props.content.heading2}

            <div className={`improvements ${props.content.theme ? props.content.theme : ``}`}>

                {props.content.tiles.map( (tile) => (
                    <div className="improvement">
                        {tile.icon}
                        <div>
                            {tile.content}
                        </div>  
                        <div>
                            {tile.hint}
                        </div>
                    </div>
                ))}
            
                <div className="improvements_more" 
                onClick={handleEvent_improvements}
                onKeyDown={(e) => ((e.key || e.keyCode === 13) && handleEvent_improvements)}
                role="button"
                tabIndex={0}>
                    <div>
                        {props.content.btn_more} <IoIosArrowDropdown />
                    </div>
                </div>
            </div>
        
            {props.content.btn_cta && <div className="btn btn-primary btn-round">
                <Link to="/technologies/">{props.content.btn_cta}</Link>
            </div>}
        </div>
    );
}