import React, { useState, useEffect }  from "react";
import { Link } from "gatsby";

import { IoMenu, IoCloseOutline } from "react-icons/io5";

export default function Nav ( { content, location} ) {

	let keyID_nav;
	const isBrowser = typeof window !== "undefined"

	let [folded, toggle_folded] = useState((isBrowser ? (window.innerWidth < 1000) : 0));
	let [clicked, toggle_clicked] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			toggle_folded((window.scrollY > 40));
			folded && toggle_clicked(false);
		});
	});

	const navbarClasses = function(classes) {
		return Object
		.entries(classes)
		.filter(pair => pair[1])
		.map(pair => pair[0])
		.join(' ');
	};

	return(
		<div className="sticky">
			<div className="container">
				<div id="nav">

					<div className={
						navbarClasses({
							navbar: true,
							folded: (folded && (window.scrollY > 40)),
							[Symbol()]: function() { this.unfolded = !this.folded},
							clicked: clicked
						})}>

						<nav className="navbar-expand-lg">

							<div className="d-flex justify-content-end">
								 <ul className="navbar-nav ml-auto">
									{content.nav.mainMenu.map(link => (
										<li className="nav-item" key={(keyID_nav = keyID_nav ?? 0, keyID_nav++)}>
											<Link to={`${link.url}`}>
												{link.text}
											</Link>
										</li>
									))}
									<li key={keyID_nav}>
										<IoCloseOutline 
											onClick={() => toggle_clicked(false)} 
											onKeyDown={(e) => ((e.key || e.keyCode === 13) && toggle_clicked(false))} 
											role="button" tabIndex={0} 
										/>
									</li>
								</ul>

								<div onClick={() => {toggle_clicked(true);toggle_folded(true)}} 
									onKeyDown={(e) => ((e.key || e.keyCode === 13) && (toggle_clicked(true), toggle_folded(true)))} 
									className="nav-unfold" role="button" tabIndex={0}>
									<IoMenu size={30} />
								</div>

								{(location.pathname !== "/inquiry/") &&
								<div>
									<Link to="/inquiry/">
										<div className="btn btn-primary">
											<div>
												<span>{(folded || (isBrowser ? (window.innerWidth < 1000) : true)) ? content.nav.ctaBtn.short : content.nav.ctaBtn.long}</span>
											</div>
										</div>
									</Link>
								</div>}
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
}