import * as React from "react";

export default function LayoutHeader () {

	return(
		<React.Fragment>
			<footer>
				<div class="container">
					&copy; {process.env.WEBSITE_APPNAME}, {new Date().getFullYear()}
				</div>
			</footer>
		</React.Fragment>
	);
}